.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.img.loading {
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: cover;
    background-color: #f5f5f5;
}

.img.loaded {
    opacity: 0;
    animation: loaded 0.5s ease-in-out forwards;
}

.img-big-display {
    width: 100%;
    height: 100vh;
    z-index: 999;
    cursor: pointer;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.85);
    transition-duration: var(--transition-duration);
    animation: fadeIn var(--transition-duration) ease-in-out forwards;
    position: fixed;
}

.img-big-display:active {
    background-color: rgba(0, 0, 0, 0.90);
}

.img-big-display-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
}

.img-big-display-container img {
    width: 90%;
    height: 90%;
    object-fit: contain;
    cursor: default;
    object-position: center;
    pointer-events: none;
}

@keyframes loaded {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}