:root {
  --box-shadow-sm: 6px 6px 0 #000;
  --box-shadow: 12px 12px 0 #000;
  --box-shadow-light-sm: 6px 6px 0 #fff;
  --box-shadow-light: 12px 12px 0 #fff;
  --border:  2px solid #000;
  --border-light:  2px solid #fff;
  --transition-duration: .15s;
  --border-radius: 2px;
}

* {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

img {
  user-select: none;
}

*::-webkit-scrollbar {
  background-color: #fff;
  width: 4px;
}
*::-webkit-scrollbar-thumb {
  background-color: #060606;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #000;
}

/* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap'); */

html {
  overflow-x: hidden;
  /* scroll-behavior: smooth; */
}
body {
  background-size: 40px 40px;
  background-image:
    linear-gradient(to right, rgb(250, 250, 250) 1px, transparent 1px),
    linear-gradient(to bottom, rgb(250, 250, 250) 1px, transparent 1px);
  margin: 0;
  font-family: monospace;
  /* font-family: Rubik,sans-serif; */
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  min-height: 100vh;
  background-color: #fff;
  overflow-x: hidden;
}

.content {
  padding: 50px 40px 0 40px;
  background-color: #000;
  margin-left: 350px;
  min-height: calc(100vh - 50px);
  width: 100%;
  background-size: 40px 40px;
  background-image:
    linear-gradient(to right, rgb(15, 15, 15) 1px, transparent 1px),
    linear-gradient(to bottom, rgb(15, 15, 15) 1px, transparent 1px);
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 800px;
  height: 100%;
  min-height: inherit;
}

.h-100 {
  min-height: 90vh;
}

.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.gap-20 {
  gap: 20px;
}
.gap-40 {
  gap: 40px;
}

.container {
  display: flex;
}

.title {
  font-size: 60px;
  font-weight: 400;
  color: #fff;
  position: relative;
  align-items: center;
  display: flex;
}

/* .title::after {
  --deco-height: 0.3125em;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(var(--deco-height) * -0.625);
  height: var(--deco-height);
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M-17 30.5C-1 22 72-4 54 13 37.9 28.2-2.5 57.5 16 55.5s72-29 104-40' stroke='%23ffffff' stroke-width='10'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h100v64H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-size: auto 100%;
  height: 100%;
  animation: blink 1s infinite;
  background-color: #fff;
  width: 30%;
  background-repeat: round;
  background-position: 0em;
} */

.cube {
  width: 20px;
  height: 60px;
  background-color: #fff;
  margin-left: 15px;
  float: right;
}

.bold {
  font-weight: 700;
}

.title-secondary {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  opacity: 0.85;
}

.link {
  text-decoration: none;
  border-radius: var(--border-radius);
  background-color: #fff;
  color: #000;
  outline: var(--border);
  transition-duration: var(--transition-duration);
  font-size: 24px;
  padding: 20px;
}
.link:hover {
  text-decoration: none;
  background-color: #000;
  outline: var(--border-light);
  box-shadow: var(--box-shadow-light);
  color: #fff;
}

.mt-1 {
  margin-top: 3rem;
}
.mt-50 {
  margin-top: 50px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.pt-50 {
  padding-top: 50px;
}
.border-top {
  border-top: 1px solid #fff;
}

.about {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  white-space: pre-line;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.opacity-none {
  opacity: 0;
}

.about-img {
  outline: var(--border-light);
  animation: 0.3s ease-in 1s 1 forwards boxShadow;
  height: fit-content;
  border-radius: var(--border-radius);
  background-color: #000;
}

.about-img img {
    object-fit: cover;
    object-position: center;
    min-height: 300px;
    max-height: 300px;
    min-width: 300px;
    max-width: 300px;
}
.flex-wrap {
  flex-wrap: wrap;
}

.blink {
  animation: blink 1s linear infinite;
}

.about span,
p {
  animation-name: fadeIn;
  animation-duration: 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes boxShadow {
  0% {
    box-shadow: none;
  }
  100% {
    box-shadow: var(--box-shadow-light);
  }
}

@media (max-width: 950px) {
  .wrap-950 {
    flex-direction: column;
    width: fit-content;
  }
}

@media (max-width: 768px) {
  .wrap-950 {
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }
  .container {
    flex-direction: column;
  }
  .title {
    font-size: 40px;
  }
  .title-secondary {
    font-size: 12px;
  }
  .about-img img {
      object-fit: cover;
      object-position: center;
      min-height: 200px;
      max-height: 200px;
      min-width: 200px;
      max-width: 200px;
  }
  .content {
    width: auto;
    margin-left: 0;
    padding: 50px 20px 0 20px;
  }
  .mt-50 {
    margin-top: 30px;
  }
}