.go-back {
    width: fit-content;
}

.go-back .back-btn {
    display: flex;
    align-items: center;
    gap: 20px;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    transition-duration: var(--transition-duration);
}

.go-back .back-btn span::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background: #fff;
    transition: width 0.3s;
}

.go-back .back-btn:hover span::after {
    width: 100%;
}

.go-back .back-btn:active {
    opacity: 0.5;
}

.go-back span {
    font-size: 22px;
}